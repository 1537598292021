import { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
function App() {
  const getData = async () => {
    try {
      const datassss = await axios.get(
        "https://hitesh.zorem.dev/wp-json/admin/web-git-json"
      );
      var result = datassss.data;
      const dataLocal = localStorage.getItem("selectData");
      if (dataLocal && JSON.parse(dataLocal).length > 0) {
        var arr = [];
        for (var i = 0; i < result.length; i++) {
          if(JSON.parse(dataLocal)[i] ){
            if ( JSON.parse(dataLocal)[i].select ) {
              arr.push({
                ...result[i],
                select: true,
              });
            } else {
              arr.push({
                ...result[i],
                select: false,
              });
            }
          }else{
            arr.push({
              ...result[i],
              select: false,
            });
          }
        }
        setdata(arr);
      } else {
        console.log("elsle",result);
        setdata(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setdata] = useState([]);

  return (
    <>
      <div className="App">
        {data.length > 0 && data.map((item, index) => {
          return (
            <div className="checkBox_main">
              <input
                className="checkBox"
                type="checkbox"
                name="check"
                checked={item.select}
                onChange={(e) => {
                  const dataCopy = [...data];
                  dataCopy[index].select = dataCopy[index].select
                    ? false
                    : true;
                  setdata(dataCopy);
                  localStorage.setItem("selectData", JSON.stringify(dataCopy));
                }}
              />
              <p
                onClick={() => {
                  const dataCopy = [...data];
                  dataCopy[index].select = dataCopy[index].select
                    ? false
                    : true;
                  setdata(dataCopy);
                  localStorage.setItem("selectData", JSON.stringify(dataCopy));
                }}
                className="label"
              >
                {item.label}
              </p>
            </div>
          );
        })}
      </div>
      <div className="iframe_main">
        {data.map((item, index) => {
          if (item.select) {
            return (
              <div className="header_main">
                <p className="iframe_label">{item.label}<a style={{marginLeft:10,fontWeight: 'normal',}} target="_blank" href={item.iframe} >Link</a></p>
                <iframe className="iframe_container" src={item.iframe}></iframe>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}

export default App;
